<template>
  <div class="warp">
    <div class="select-box">
      <label class="label">网点</label>
      <van-dropdown-menu class="drop-down-class">
        <van-dropdown-item :title="checkedNets" ref="item">
          <van-checkbox-group v-model="checkedNetIds" res="checkboxGroup" @change="changeNet">
            <div v-for="(item,index) in netList" :key="item.value" role="button" :tabindex="index" class="van-cell van-cell--clickable van-dropdown-item__option">
              <div class="van-cell__title">
                <span>
                  <van-checkbox :name="item.value" checked-color="#A9B910">{{ item.text }}</van-checkbox></span>
              </div>
            </div>
          </van-checkbox-group>
        </van-dropdown-item>
      </van-dropdown-menu>
    </div>
    <div class="select-box">
      <label class="label">点位</label>
      <van-dropdown-menu  class="drop-down-class">
        <van-dropdown-item :title="checkedVmcs" ref="item">
          <van-checkbox-group v-model="checkedAddresses" res="checkboxGroup" @change="changeVmc">
            <div v-for="(item,index) in vmcList" :key="item.value" role="button" :tabindex="index" class="van-cell van-cell--clickable van-dropdown-item__option">
              <div class="van-cell__title">
                <span>
                  <van-checkbox :name="item.value" checked-color="#A9B910">{{ item.text }}</van-checkbox></span>
              </div>
            </div>
          </van-checkbox-group>
        </van-dropdown-item>
      </van-dropdown-menu>
    </div>
    <div class="count-class">
      <div class="count-nums">{{ outStocks }}</div>
      <div class="label">缺货数量（个）</div>
    </div>
    <van-tabs v-model:active="active" type="card">
      <van-tab title="商品缺货明细" name="1"></van-tab>
      <van-tab title="商品库存情况" name="2"></van-tab>
      <van-tab title="缺货机器排名" name="3"></van-tab>
    </van-tabs>
    <div v-if="active == 1 || active == 2" class="data-box">
      <div class="cell-box">
        <div class="title first-title">商品名称</div><div class="count-num first-title">{{ active == 1 ? '缺货' : '库存' }}数量</div>
      </div>

      <div v-if="dataCount.length && dataCount[active-1].length == 0" class="empty-data">暂无数据</div>
      <div class="cell-box" v-for="(item, key) in dataCount[active-1]" :key="key">
        <div class="title">{{ item.prodName }}</div><div class="count-num">{{ item.stocksCount }}</div>
      </div>
    </div>
    <div v-if="active == 3" class="data-box">
      <div class="cell-box">
        <div class="title first-title">机器信息</div><div class="count-num first-title">缺货数量</div>
      </div>
      <div v-if="dataCount?.[active-1].length == 0" class="empty-data">暂无数据</div>
      <div class="vmc-box" v-for="(item, key) in dataCount[active-1]" :key="key">
        <div class="vmc-infos">
          <div class="label-title">机器编号：<span>{{ item.vmcName }}</span></div>
          <div class="label-title">网点：<span>{{ item.netName }}</span></div>
          <div class="label-title">点位：<span>{{ item.address }}</span></div>
          <div class="label-title">上次补货：<span>{{ item.repairTime }}</span></div>
        </div>
        <div class="out-nums">
          <span :class="active == 3 ? 'active-three' : ''" @click="goDetail(item.vmcId)">
            {{ item.outStocks }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { networkList, vmcinfoList, replenishment } from '../../assets/api/goodsSelect.js'
export default {
  data() {
    return {
      netId: '',
      checkedNets: '请选择网点',
      checkedVmcs: '请选择点位',
      checkedNetIds: [],
      netList: [],
      checkedAddresses: [],
      address: '',
      vmcList: [],
      active: 1,
      dataCount: [],
      outStocks: 0,
      netChecked: [],
      showNet: false,
      showVmc: false
    }
  },
  mounted() {
    this.getNetData()
    this.getVmcList()
    this.initData()
  },
  methods: {
    changeNet() {
      this.netId = this.checkedNetIds.join(',')
      this.netList.forEach(item => {
        if (this.checkedNetIds.indexOf(item.value) > -1) {
          this.checkedNets = item.text
        }
      })
      this.checkedNets = ''
      this.checkedNets = '已选中(' + this.checkedNetIds.length + ')网点'
      this.getVmcList()
      this.initData()
    },
    changeVmc() {
      this.address = this.checkedAddresses.join(',')
      this.checkedVmcs = ''
      this.checkedVmcs = '已选中(' + this.checkedAddresses.length + ')点位'
      this.initData()
    },
    getNetData() {
      this.netList = []
      networkList({ current: 1, size: 1000 }).then(res => {
        if (res.data.data.records.length > 0) {
          res.data.data.records.forEach(item => {
            const it = {
              text: item.networkName,
              value: item.id
            }
            this.netList.push(it)
          })
        }
      })
    },
    getVmcList() {
      this.vmcList = []
      if (!this.netId) return
      vmcinfoList({ netId: this.netId }).then(res => {
        if (res.data.data.records.length > 0) {
          res.data.data.records.forEach(item => {
            const it = {
              text: item.address,
              value: item.address
            }
            this.vmcList.push(it)
          })
        }
      })
    },
    initData() {
      this.dataCount = []
      replenishment({ netId: this.netId, address: this.address}).then(res => {
        this.outStocks = res.data.data.outStocks
        this.dataCount.push(res.data.data.goodsOutStocksList)
        this.dataCount.push(res.data.data.goodsStocksList)
        this.dataCount.push(res.data.data.infoCounts)
      })
    },
    goDetail(vmcId) {
      this.$router.push({
        name: `net_point_detail_infos`,
        query: {
          vmcId: vmcId,
          sign: 'quehuoxinxi'
        }
      })
    }
  }
}
</script>

<style scoped lang="less">
::v-deep(.van-dropdown-menu__bar) {
  box-shadow: none;
}
.empty-data {
  width: 100%;
  text-align: center;
  height: 24px;
  line-height: 24px;
  font-size: 12px;
  color: #666;
}
.warp {
  padding: 15px 10px;
  .select-box {
    display: flex;

    .label {
      line-height: 48px;font-size: 16px;width: 50px
    }

    .drop-down-class {
      width: calc(100% - 50px);
      text-align: center;
      height: 50px;
      line-height: 50px;
      font-size: 16px;
    }
  }
  .count-class {
    margin: 15px 0;
    width: 100%;
    height: 60px;
    background: #fff;
    box-shadow: 0.5px 0.5px 0.5px #6c5a5a;
    text-align: center;
    .count-nums, .label {
      height: 30px;
    }
    .count-nums {
      color: #333;
      font-weight: bold;
      line-height: 40px;
    }
    .label {
      line-height: 30px;
      color: #666;
    }
  }

  .data-box {
    margin-top: 15px;

    .cell-box {
      display: flex;
      justify-content: space-between;
      text-align: center;
      line-height: 20px;
      padding: 8px 0;
      .title {
        width: calc(100% - 100px);
      }
      .count-num {
        width: 100px;
        justify-content: center;
        align-items: center;
        display: flex;
      }
      .first-title {
        font-size: 15px;
        color: #333;
        font-weight: bold;
      }
    }
    .vmc-box {
      display: flex;
      justify-content: space-between;
      text-align: center;
      margin-bottom: 10px;
      border-bottom: 1px solid #999;
      padding-bottom: 8px;
      .vmc-infos {
        width: calc(100% - 100px);
        text-align: left;
        height: auto;
        color: #666;
        .label-title {
          line-height: 20px;
        }
        span {
          color: #333;
        }
      }
      .out-nums {
        width: 100px;
        justify-content: center;
        align-items: center;
        display: flex;
        color: #37C144;
        .active-three {
          border-bottom: 1px solid #37C144;
        }
      }
    }
  }
}
</style>
